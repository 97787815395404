import { z } from "zod";

export const registerSchema = z
  .object({
    firstName: z.string().min(2, "First name must be at least 2 characters"),
    lastName: z.string().min(2, "Last name must be at least 2 characters"),
    email: z.string().email("Please enter a valid email address"),
    password: z
      .string()
      .min(8, "Password must be at least 8 characters")
      .regex(/[A-Z]/, "Password must contain at least one uppercase letter")
      .regex(/[a-z]/, "Password must contain at least one lowercase letter")
      .regex(/[0-9]/, "Password must contain at least one number"),
    repeatPassword: z.string(),
    phoneNumber: z.string().min(10, "Please enter a valid phone number"),
    companyName: z
      .string()
      .min(2, "Company name must be at least 2 characters"),
    companyStreet: z.string().min(2, "Company address is required"),
    companyCity: z.string().min(2, "Company city is required"),
    companyState: z.string().min(2, "Company state is required"),
    companyCounty: z.string().min(2, "Company county is required"),
    companyZip: z
      .string()
      .min(5, "ZIP code must be at least 5 characters")
      .regex(/^\d+$/, "ZIP code must contain only numbers"),
    role: z
      .number()
      .nullable()
      .refine((val) => val !== null && val >= 0, {
        message: "Please select a role",
      }),
  })
  .refine((data) => data.password === data.repeatPassword, {
    message: "Passwords don't match",
    path: ["repeatPassword"],
  });

export const loginSchema = z.object({
  email: z.string().email("Please enter a valid email address"),
  password: z.string().min(1, "Password is required"),
});

export const changePasswordSchema = z
  .object({
    currentPassword: z.string().min(1, "Current password is required"),
    newPassword: z
      .string()
      .min(8, "Password must be at least 8 characters")
      .regex(/[A-Z]/, "Password must contain at least one uppercase letter")
      .regex(/[a-z]/, "Password must contain at least one lowercase letter")
      .regex(/[0-9]/, "Password must contain at least one number"),
    repeatPassword: z.string(),
  })
  .refine((data) => data.newPassword === data.repeatPassword, {
    message: "Passwords don't match",
    path: ["repeatPassword"],
  });
